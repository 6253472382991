
import { defineComponent } from 'vue';
import {
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  ShareAltOutlined,
} from '@ant-design/icons-vue';
import { NumberFormat, FormatWan } from '@/utils/number-util';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { queryFakeList } from '@/api/list/basic-list';

export default defineComponent({
  name: 'Applications',
  setup() {
    const { reload, context } = useFetchData(() => {
      return new Promise<any>((resolve, reject) => {
        queryFakeList({ count: 16 })
          .then(list => {
            resolve({
              data: list,
              success: true,
              total: 16,
            });
          })
          .catch(reject);
      });
    });

    return {
      reload,
      context,

      // func
      NumberFormat,
      FormatWan,
    };
  },
  components: {
    DownloadOutlined,
    EditOutlined,
    EllipsisOutlined,
    ShareAltOutlined,
  },
});
